<template>
    <v-container>

        <div class="bg"></div>

        <v-row
            v-if="showCreatePasskey"
            class="mt-5 mb-1"
        >
            <v-col>
                <add-passkey :showCreatePasskey="showCreatePasskey"></add-passkey>
            </v-col>
        </v-row>

        <v-row :class="{'mt-1': showCreatePasskey, 'mt-5': !showCreatePasskey}">
            <v-col>
                <users></users>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import {mapState} from 'vuex'
import addPasskey from '@/components/management/AddPasskey'
import users from '@/components/management/Users'

export default {
    components: {
        addPasskey,
        users
    },

    data: () => ({
        showCreatePasskey: false
    }),

    computed: {
        ...mapState({
            role: state => state.common.user.role,
            email: state => state.common.user.email
        })
    },

    created() {
        if (this.role === 'minor')
            this.$router.push('/')

        this.checkShowCreatePasskey()
    },

    watch: {
        role() {
            if (this.role === 'minor')
                this.$router.push('/')
        }
    },

    methods: {
        async checkShowCreatePasskey() {
            if (!this.email) return

            if (!window.PublicKeyCredential ||
                !PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable ||
                !PublicKeyCredential.isConditionalMediationAvailable)
                return

            const results = await Promise.all([
                PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
                PublicKeyCredential.isConditionalMediationAvailable(),
            ])

            if (results.every(r => r === true))
                this.showCreatePasskey = true
        }
    }
}
</script>

<style>
.card-title.v-card__title {
    word-break: normal;
}
</style>